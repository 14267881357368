/**
 * @page Homepage
 */

body.is-loading {
  max-height: 100vh;
  overflow: hidden;
}

body[data-page=home]:before {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  // background-color: $color-primary;
  background-color: $color-brown-bg;
  pointer-events: none;
  z-index: 95;
  transition: 0.6s linear all;
}

body.is-loaded:before {
  opacity: 0;
}

.home-main {
  // background-color: $color-primary;
  background-color: $color-brown-bg;
  position: relative;
}

.home-main>* {
  position: relative;
}

.home-main-backgrounds {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0;
  transition: 1.2s linear all;
}

.home-main-backgrounds.is-loaded {
  opacity: 1;
}

.home-main-backgrounds video {
  position: absolute;
  top: 0;
  width: 100vw;
}

/* Hero
/* -------------------------------------------------------------------------- */
.home-hero {
  // padding-bottom: $spacing-6 * 2;
}

// .home-hero:after {
//   content: '';
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   left: 0;
//   background: linear-gradient(
//     to bottom, rgba($color-primary, 0), $color-primary
//   );
//   height: $spacing-6 * 2;
// }

.home-hero>.container {
  min-height: 85vh;
  padding-top: $spacing-6;
  padding-bottom: $spacing-6;

  @include on-tablet {
    min-height: 100vh;
  }
}

.home-hero-header {
  height: 100%;
  flex-grow: 1;
}

.home-hero-footer {
  opacity: 0;
  transition: 1.2s linear all;
}

.home-hero-footer.is-loaded {
  opacity: 1;
}

.home-hero-footer a:focus {
  outline: 3px solid rgba($color-secondary, 0.2);
}

.home-hero-footer .icon {
  width: 24px;
  height: 24px;
  fill: $color-secondary;
  filter: drop-shadow(0 2px 2px rgba(black, 0.5));
  animation: 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) hero-chevron-bob infinite;
}

@keyframes hero-chevron-bob {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}


/* Masthead
/* -------------------------------------------------------------------------- */
.masthead {
  transform: scale(0.95);
  transition: 2s cubic-bezier(0.165, 0.84, 0.44, 1) all;
  position: relative;
  z-index: 99;
}

.masthead>span {
  width: 250px;
  position: relative;
  display: block;

  @include on-mobile-lg {
    width: 280px;
  }

  @include on-tablet {
    width: 400px;
  }
}

.masthead>span:nth-of-type(1) {
  // height: 91px;
  overflow: hidden;
}

.masthead>span:nth-of-type(2) {
  // height: 19px;
}

.masthead>span:nth-of-type(3) {
  // height: 75px;
  overflow: hidden;
}

.masthead img {
  max-width: 100%;
  display: block;
  transition: 2s cubic-bezier(0.075, 0.82, 0.165, 1) all;
}

.masthead>span:nth-of-type(1)>img {
  transform: translateY(100%);
  opacity: 0;
}

.masthead>span:nth-of-type(2)>img {
  transform: scaleX(0);
}

.masthead>span:nth-of-type(3)>img {
  transform: translateY(-100%);
  opacity: 0;
}

.masthead .masthead-glow {
  position: absolute;
  width: 3px;
  height: 3px;
  top: 50%;
  left: 87%;
  transform: translateY(-50%);
  opacity: 0;
}

.masthead .masthead-glow:after {
  content: '';
  position: absolute;
  display: block;
  background: url('../img/masthead/masthead-400px-glow.png');
  background-size: 100% 100%;
  width: 110px;
  height: 110px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Masthead loader states
.masthead.is-loading {
  &>span:nth-of-type(2)>img {
    transition: 9s cubic-bezier(0.165, 0.84, 0.44, 1) all;
    transform: scaleX(0.6);
  }
}

.masthead.is-loaded {
  transform: scale(1);

  &>span:nth-of-type(1)>img {
    transform: none;
    opacity: 1;
    transition-delay: 1s;
  }

  &>span:nth-of-type(2)>img {
    transform: scaleX(1);
    transition: 2.5s cubic-bezier(0.165, 0.84, 0.44, 1) all;
  }

  &>span:nth-of-type(3)>img {
    transform: none;
    opacity: 1;
    transition-delay: 1s;
  }

  .masthead-glow {
    animation: 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) masthead-glow-animation forwards;
    animation-delay: 1s;
  }
}

@keyframes masthead-glow-animation {
  0% {
    left: 10%;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    left: 90%;
    opacity: 0;
  }
}


/* Feature Section
/* -------------------------------------------------------------------------- */
.home-features {
  padding-top: $spacing-6 * 2;
  padding-bottom: $spacing-6 * 2;
  overflow: hidden;
  background-color: $color-primary;

  @include on-tablet {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

.features-body {
  margin-top: auto;
}

.features-body p,
.feature-description {
  color: white;
  font-size: 14px;
  letter-spacing: 1px;
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.6;
  position: relative;
  z-index: 20;
  text-shadow: 0 0 12px rgba(black, 1);

  @include on-tablet {
    font-size: 16px;
    text-shadow: 0 0 15px rgba(black, 1);
    line-height: 2;
  }

  @include on-desktop-xlg {
    max-width: 540px;
    font-size: 18px;
  }

  &::selection {
    background-color: $color-secondary;
  }
}

.features-carousel {
  position: relative;
  z-index: 20;
  padding: $spacing-4;
  pointer-events: none;

  @include on-tablet {
    padding: 0;
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
}

.feature-item {
  position: relative;
  text-align: center;
  transition: 1.2s linear all;

  @include on-tablet {
    opacity: 0;
  }
}

.slick-current .feature-item {
  opacity: 1;
}

// Carousel Buttons

.features-carousel-controls {
  position: relative;
  height: 48px;
  margin-bottom: $spacing-6;

  @include on-tablet {
    position: static;
    margin-bottom: 0;
    height: auto;
  }
}

.features-carousel-controls__button {
  background-color: $color-primary;
  border: 0;
  fill: white;
  padding: $spacing-4;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;

  &:focus {
    outline: 3px solid rgba($color-primary, 0.5);
  }
}

.features-carousel-controls__button .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
}

.features-carousel-controls__button.is-prev {
  left: 0;
}

.features-carousel-controls__button.is-next {
  right: 0;
}

// Feature Title Text

.feature-title {
  font-family: $typeface-secondary;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin: 0 auto $spacing-6;
  display: inline-block;
  text-align: center;

  @include on-tablet {
    text-align: left;
    font-size: 42px;
    letter-spacing: 6px;
    margin-bottom: $spacing-6 * 2;
  }
}

.feature-title-line {
  color: $color-primary;
  display: inline-block;
  opacity: 0;
  transition: 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) all;
}

// .feature-title-line Modifiers
.feature-title-line {

  &.is-colored {
    transform: translateY(2em);
    font-size: 1.2em;
    color: $color-primary;
  }

  &.is-indent-1 {
    transform: translateY(2em);
    transition-delay: 0.4s;

    @include on-tablet {
      margin-left: 4rem;
    }
  }

  &.is-indent-2 {
    transform: translateY(3em);
    transition-delay: 0.8s;

    @include on-tablet {
      margin-left: 8rem;
    }
  }

  &.is-underlined {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -$spacing-3;
      height: 2px;
      background-color: $color-primary;

      @include on-tablet {
        left: 2rem;
        right: 3rem;
      }
    }
  }

  small {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1.5rem;
    transform: translateY(1em);
    text-align: center;
    font-size: 12px;
    font-family: $typeface-primary;
    font-weight: normal;
    letter-spacing: 1px;

    @include on-tablet {
      left: 2rem;
      right: 3em;
      font-size: 16px;
      letter-spacing: 2px;
    }
  }

}

// Feature line animations
.feature-title-line.animate {
  &.is-colored {
    transform: none;
    opacity: 1;
  }

  &.is-indent-1 {
    transform: none;
    opacity: 1;
  }

  &.is-indent-2 {
    transform: none;
    opacity: 1;
  }
}


// Indicators

.features-indicators {
  position: relative;
  z-index: 20;
}

.features-indicators ul {
  @include m-list-unstyled;
  text-align: center;
  margin-bottom: 0;
}

.features-indicators li {
  display: inline-block;
}

.features-indicators button {
  background: rgba(black, 0.3);
  border: 0;
  font-size: 0;
  padding: 0;
  margin: 0 2px;
  height: 3px;
  width: 18px;

  &:focus {
    background: rgba(black, 0.75);
    outline: none;
  }
}

.features-indicators .slick-active button {
  background-color: black;
}

// Features Background

.features-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.features-background div {
  height: 100%;
}

.features-background img {
  max-width: 100%;
  display: block;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

/* Location Section
/* -------------------------------------------------------------------------- */
.home-location {
  border-top: 1px solid rgba(white, 0.05);
  padding-top: $spacing-6 * 2;
  padding-bottom: $spacing-6 * 2;
  color: white;
  background-color: $color-primary;
}

.pattern-left,
.pattern-right {
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 0;
}

.pattern-left {
  width: 100%;
  height: 300px;
  background-image: url('../img/home/pattern-left-01.jpg');
  top: 0;
  left: 0;

  @include on-tablet {
    width: 650px;
    height: 620px;
  }
}

.pattern-right {
  width: 100%;
  height: 200px;
  background-image: url('../img/home/pattern-right-01.jpg');
  background-position-x: 100%;
  top: 40%;
  right: 0;

  @include on-tablet {
    width: 420px;
    height: 420px;
  }
}

/* Registration Section
/* -------------------------------------------------------------------------- */
.home-registration {
  border-top: 1px solid rgba(white, 0.05);
  padding-top: $spacing-6 * 2;
  padding-bottom: $spacing-6 * 2;
  color: white;
}

.home-registration .form-check-label {
  color: white;
  // text-transform: none;
  text-transform: uppercase;
  font-size: 14px;

  @include on-tablet {
    font-size: 12px;
  }
}

.home-registration hr {
  background-color: rgba($color-secondary, 0.3);
}

.home-registration-tnc .form-check {
  padding-left: 2em;
  padding-left: 0;
}

.home-registration-tnc .form-check-input {
  margin-left: -2em;
}

.home-registration-tnc em {
  display: block;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  color: $color-secondary;
  letter-spacing: 1px;
}

.home-registration-tnc .form-check-label {
  position: relative;
  top: 5px;
}

.home-registration .accordion {
  margin-top: 1.5rem;
}

.home-registration .accordion .btn {
  width: 100%;
  color: $color-secondary;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    color: white;
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
}

.home-registration .accordion .btn .badge {
  background-color: $color-secondary-d2;
  // padding: 0.3em 0.75em;
  padding: 1rem;
  border-radius: 45px;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-registration .accordion .card {
  background-color: transparent;
  border: 1px solid rgba($color-secondary-d2, 0.6)
}

.home-registration .accordion .card:first-of-type,
.home-registration .accordion .card-header {
  border-bottom: 1px solid rgba($color-secondary-d2, 0.6);
}

#formMoreOption {
  border: none;
  background: #fff;
}

.accordian-button {
  letter-spacing: 0.2em;
  color: $color-label;
}

.accordion .card {
  .is-open {
    display: block;
  }

  .is-close {
    display: none;
  }
}

.accordion .card .collapsed {
  .is-open {
    display: none;
  }

  .is-close {
    display: block;
  }
}

// QapTcha overrides
.home-registration .QapTcha {
  margin: 0 auto;

  @include on-tablet {
    margin: 0;
  }
}

.home-registration .QapTcha .bgSlider {
  border-radius: 0;
  background: none;
  background-color: white;
  position: relative;
  height: 48px;
}

.home-registration .QapTcha .bgSlider:after {
  content: 'Slide to Submit';
  display: block;
  color: $color-text;
  position: absolute;
  top: 50%;
  left: 48px;
  right: 0;
  transform: translateY(-50%);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}

.site-cn .home-registration .QapTcha .bgSlider:after {
  content: '请滑动提交';
}

.home-registration .QapTcha .Slider {
  background: $color-secondary;
  width: 60px;
  top: 2px;
  left: 2px;
  height: 42px;
  z-index: 90;
}

.home-registration .QapTcha .Slider:after {
  content: '›';
  position: absolute;
  top: calc(50% - 3px);
  left: 50%;
  font-size: 24px;
  color: white;
  font-weight: bold;
  transform: translate(-50%, -50%);
}

section.section {
  padding: 6rem 0;
}

section.video-section {
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.video-section .video-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.video-section .video-bg video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.banner-inner {
  margin-top: calc(90px + 5rem);
}

.banner-inner svg {
  max-width: 100%;
  overflow: visible;

  @include media-breakpoint-down(md) {
    max-width: 80%;
  }

  @include media-breakpoint-down(sm) {
    max-width: 70%;
  }
}

#Rectangle_9 {
  transition: all 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: scaleX(0);
  transform-origin: center center;
}

#Rectangle_9.triggered {
  transform: scaleX(1);
}

.fade-up {
  display: flex;
  transform: translateY('20px');
  opacity: 0;
}

section.concept-section {
  // background: url('../img/bg/concept-bg.png') no-repeat;
  // background-size: cover;
  // background-position: right;
  // min-height: 100vh;
  // position: relative;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  min-height: 100vh;
}

section.concept-section .box-container {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 1;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

section.concept-section .box-container>div {
  flex: 0 0 33.3333%;
  height: 100vh;
  min-height: 700px;
  overflow: hidden;
  position: relative;

  @include media-breakpoint-down(sm) {
    min-height: 550px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}

section.concept-section .box-container div .box-bg:after,
.box-content-overbox:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  // background: rgba(75, 77, 72, .35);
  background: rgba(21, 24, 16, .2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.box-content-overbox:after {
  z-index: -1;
}

section.concept-section .box-container.is-open {
  visibility: hidden;

  @include media-breakpoint-down(sm) {
    visibility: visible !important;
  }
}

section.concept-section .box-container div .box-bg {
  background-size: cover;
  background-position: center center;
  height: 110%;
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    position: absolute;
    width: 100%;
    transform: none !important;
  }
}

.box-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 1;
  padding: 0 1rem;

  @include media-breakpoint-down(sm) {
    position: relative;
    height: 100%;
    padding: 12rem 1rem;

    opacity: 1 !important;
    visibility: visible !important;
    transform: none !important;

  }
}

.box-inner h2 {
  color: #fff;
  font-size: 2rem !important;
  line-height: 2rem !important;
}

.box-inner-wrapper {
  @include media-breakpoint-down(sm) {
    // max-width: 300px;
    // margin: 0 auto;
  }
}

.box-content-overbox {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-image: url('../img/boxes/overlay-1.png');
  background-size: cover;
  padding-top: 10rem;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.box-inner-content {
  display: none;

  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

.box-inner-content.is-open {
  display: block;
}

section.concept-section .box-container div:hover .box-bg {
  transform: translateY(-20px)
}

section.grey-bg:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(75, 77, 72, .35);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.box-inner-find-btn,
.box-inner-see-btn {
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  margin: 1rem 0;
  display: block;
  text-decoration: underline;
}

.box-inner-find-btn {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.box-inner-see-btn {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

h2.overbox-sub {
  color: #fff;
  font-weight: 700;
  font-size: 3rem !important;
  line-height: 3rem !important;
}

.box-content-overbox .overbox-tag {
  color: #fff;
  text-transform: uppercase;
  font-size: 1.4rem !important;
  line-height: 1.6rem !important;
  font-weight: 700;
}

.box-inner-content .overbox-tag {
  font-size: 1.4rem;
  line-height: 1.6rem;
}

// .box-content-overbox {
//   position: relative;
// }

.box-content-overbox .overbox-tagline {
  position: relative;
}

.box-content-overbox .overbox-tagline:before {
  content: '';
  height: 3px;
  width: 10%;
  background: #fff;
  top: -1.5rem;
  left: 0;
  position: absolute;
}

h2.living-swiper-h2 {
  position: relative;

  @include media-breakpoint-down(sm) {
    margin-top: 1rem;
  }
}

h2.living-swiper-h2:before {
  content: '';
  height: 3px;
  width: 5rem;
  background: $color-text-brown;
  top: -1.5rem;
  left: 0;
  position: absolute;
}

.overbox-points {
  list-style: none;
}

.overbox-points li {
  margin-bottom: $spacer;
}

.overbox-back-button {
  position: absolute;
  top: 9.5rem;
  left: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overbox-back-button .box-back-arrow {
  margin-right: .5rem;
  animation: fade-left 1.5s ease-in-out infinite;
}

.overbox-back-button:hover .box-back-arrow path {
  fill: $color-theme !important;
}

h2.overbox-tag {
  @include media-breakpoint-down(sm) {
    font-size: 1.5rem !important;
    line-height: 1.5rem !important;
  }
}

.concepts-container {
  position: relative;
  z-index: 1;
}

.concepts-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1rem 0;
}

.concepts-items>div {
  // align-self: center;
  text-align: center;
}

.concepts-items>div img {
  margin-bottom: $spacer;
}

.concepts-items>div .concept-desc {
  color: #fff;
}

section.concept-2-section {
  background: url('../img/bg/concept-2-bg.png') no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-p {
  width: 50%;
  text-align: center;
  color: #fff;
  margin: 0 auto;


  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 0 1rem;
  }
}

section.slide-section {
  background: #E5DDCC;
  overflow: hidden;
}

.swiper-container.living-swiper {

  overflow: visible;

  @include media-breakpoint-down(xs) {
    padding: 0 1rem;
    overflow: hidden;
  }
}

.swiper-container.living-swiper .sub,
.swiper-container.living-swiper h2 {
  color: $color-text-brown;
}

.living-swiper .swiper-wrapper .swiper-slide img {
  max-width: 100%;
  height: auto;
}

// .living-swiper {
//   overflow: visible;

//   @include media-breakpoint-down(sm) {
//     overflow: hidden;
//   }
// }

.living-swiper .swiper-wrapper .img-wrapper {
  margin-bottom: 1rem;
}

.living-swiper .swiper-wrapper .living-desc h3 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: $color-text-brown;

  @include media-breakpoint-down(md) {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}

.living-desc-hover {
  position: relative;
  padding-right: 2rem;
}

.living-desc-hover-plus {
  content: '';
  width: 40px;
  height: 40px;
  background: url('../img/icons/slider-desc-plus.png?v=2');
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 1;
}

.living-desc-hover p {
  color: $color-text-brown;
  transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0;
}

.living-desc-hover-plus:hover {
  opacity: 0;
}

.living-desc-hover-plus:hover~p {
  opacity: 1;
}

.arrow-container {
  width: 140px;
  position: absolute;
  display: flex;
  right: 0;
  bottom: 0;
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    position: relative;
    width: 100%;
  }
}

.arrow-container .arrow:focus {
  outline: none !important;
}

section.masterplan-section {
  position: relative;
}

section.masterplan-section .masterplan-img {
  width: 100%;
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.masterplan-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;

  @include media-breakpoint-down(md) {
    position: relative;
    background: $color-secondary;
  }
}

.masterplan-inner-container {
  min-height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5vh 0vh;
}

.masterplan-inner-container .row {
  margin: 0;
}

.theme-color {
  color: $color-text-brown;
}

.p-text-color-2 {
  color: $color-text-2;
}

.masterplan-inner p {
  max-width: 80%;
  margin: 0 auto;

  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
}

.pan-container {
  position: relative;
}

.pan-container .magnifier {
  position: absolute;
  right: 1rem;
  top: 1rem;
  animation: float 2s ease-in-out infinite;
  cursor: pointer;
  z-index: 1;
  width: 40px;
  height: 40px;
}

.pan-container .magnifier .mag-active,
.pan-container .magnifier .mag-inactive {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  overflow: visible;
}

.pan-container .magnifier .mag-active {
  transform: scale(1);
  transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.pan-container .magnifier .mag-inactive {
  transform: scale(0);
  transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.pan-container .magnifier.active .mag-active {
  transform: scale(0);
}

.pan-container .magnifier.active .mag-inactive {
  transform: scale(1);
}

.pan-container .magnifier .mag-active path {
  fill: $color-theme;
  transition: color .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.pan-container .magnifier .mag-inactive path {
  fill: #fff;
  transition: color .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-10px);
  }

  100% {
    transform: translatey(0px);
  }
}

.pan-section {
  transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1) !important;
}

.residence-section img {
  width: 100%;
}

.residence-section-inner {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;

  @include media-breakpoint-down(md) {
    // position: relative;
    // background: $color-secondary;
  }
}

.residence-section-inner .residence-section-inner-container {
  height: 50%;
  display: flex;
  align-items: center;

  padding-top: 5vh;
  padding-bottom: 5vh;

  @include media-breakpoint-down(md) {
    height: 40%;
  }
}

.coming-soon-label {
  background: $color-label;
  display: inline-block;
  padding: 0.5rem 2rem;
  font-weight: 400;
  color: #fff;
  border-radius: 20px;
}

.coming-soon-text {
  font-weight: 700;
  color: $color-text-brown;
  font-size: 1.2rem;
}

.residence-section-inner h3 {
  color: $color-text-brown;
  text-transform: uppercase;
}

.gallery-section {
  background: $color-theme-2;
}

.swiper-container.gallery-swiper,
.swiper-container.gallery-thumb-swiper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.swiper-container.gallery-thumb-swiper .swiper-slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.gallery-container {
  // display: grid;
  // grid-template-columns: minmax(500px, auto) min(200px);
}

.gallery-container>div {
  padding: 1rem;

  @include media-breakpoint-down(lg) {
    padding: 0 !important;
  }
}

.thumb-img-wrapper {
  height: 100%;
  // padding-bottom: 1rem;
  display: flex;
  // padding: 1rem 0;
  box-sizing: border-box;
}

.thumb-img-wrapper img {
  @include media-breakpoint-down(lg) {
    height: 150px !important;
  }

  @include media-breakpoint-down(sm) {
    height: 60px !important;
  }
}

.gallery-swiper-wrapper {
  padding-right: 20% !important;

  @include media-breakpoint-down(lg) {
    padding: 1rem !important;
  }
}

.gallery-thumb-swiper-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  width: 20%;
  height: 100%;

  @include media-breakpoint-down(lg) {
    position: relative;
    max-width: 100%;
    width: 100%;
    margin: 1rem 0;
  }
}

.gallery-thumb-swiper .swiper-slide {
  cursor: pointer;
  position: relative;
}

.gallery-thumb-swiper .swiper-slide .thumb-img-wrapper:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border: 5px solid $color-label;
  opacity: 0;
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
  top: 0;
  left: 0;
}

.gallery-thumb-swiper .swiper-slide.swiper-slide-thumb-active .thumb-img-wrapper:after {
  content: '';
  opacity: 1;
}

.gallery-prev,
.gallery-next {
  position: absolute;
  background: $color-text-brown;
  width: 2rem;
  height: 4rem;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.gallery-prev {
  top: 50%;
  left: 0;
}

.gallery-next {
  top: 50%;
  right: 0;
}

.gallery-prev.swiper-button-disabled,
.gallery-next.swiper-button-disabled {
  opacity: .5;
}

.gallery-prev:focus,
.gallery-next:focus {
  outline: none !important;
}

.gallery-desc {
  position: absolute;
  // width: 100%;
  width: 60%;
  margin: 0 auto;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  z-index: 4;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 1rem;
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);

  & span {
    background: #{$color-gallery-desc-span};
    box-shadow: 0px 0px 6px 8px #{$color-gallery-desc-span};
  }
}

.gallery-desc.blur {
  opacity: 0;
  transform: scaleX(0);
}

section.location-section {
  background: $color-brown-bg;
}

.location-col h2 {
  color: $color-theme-2;

  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.location-spot-row {
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
}

// .location-spot-row .dot {
//   position: relative;
// }

// .location-spot-row .dot:after {
//   content: '';
//   position: absolute;
//   background-image: url('../img/dotted.svg');
//   background-repeat: repeat-y;
//   top: 0;
//   left: 0;
//   width: 1px;
//   height: 100%;
// }
.location-spot-row>div {
  display: flex;
  // align-items: center;
  // justify-content: center;
  color: $color-theme-2;
}

.location-spot-row:not(:first-child)>div {
  padding-top: 1rem;
}

.location-spot-row .location-title {
  position: relative;
  margin-right: 3rem;
  width: 50%;
  text-transform: uppercase;
  font-size: 65%;
  display: flex;
  justify-content: flex-end;
  letter-spacing: .2rem;

  @include media-breakpoint-down(sm) {
    // font-size: 80%;
    // width: 50%;
  }
}

.location-spot-row .location-title.bold {
  font-weight: 900;
}

.location-spot-row .location-title:after {
  content: '';
  position: absolute;
  background: url('../img/dotted.png') repeat-y;
  right: -3rem;
  top: 0;
  width: 1px;
  height: 100%;
  background-position: env();
  background-size: auto;
}

.location-spot-row .location-distance {
  position: relative;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: 80%;

  // @include media-breakpoint-down(sm) {
  //   font-size: 80%;
  // }
}

.location-spot-row.pgh-logo .location-distance {
  margin-left: -4.5rem;
  background: $color-brown-bg;
}

.location-spot-row .location-distance .dot {
  content: '';
  position: absolute;
  width: 1rem;
  height: 1rem;
  left: -2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.location-spot-row .location-distance .dot .dot-container {
  width: .8rem;
  height: .8rem;
  position: relative;
}

.location-spot-row .location-distance .dot .dot-container:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, .5);
  animation: growing 1s infinite;
}

@keyframes growing {
  0% {
    opacity: 1;
    transform: scale(0.8);
  }

  100% {
    opacity: 0;
    transform: scale(3);
  }
}

.location-spot-row .location-distance .dot .dot-container .dot-inner {
  position: absolute;
  background: $color-label;
  border-radius: 5rem;
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.location-spot-row .location-distance .dot.active .dot-container .dot-inner {
  background: #9B6B31;
}

.location-spot-row .location-distance .dot .dot-container .dot-outer {
  position: absolute;
  background: $color-theme-2;
  border-radius: 5rem;
  z-index: 0;
  width: 100%;
  height: 100%;
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: scale(1);
}

.location-spot-row .location-distance .dot.active {
  pointer-events: none;
}

.location-spot-row .location-distance .dot.active .dot-container .dot-outer {
  transform: scale(1.5);
}

.location-spot-logo {
  max-width: 150px;
}

.stroke {
  // stroke-dasharray: 70%;
  // stroke-dashoffset: 100%;
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.stroke.hidden {
  opacity: 0;
}


$resort_world_genting_1_strokedasharray: 428.5;
$resort_world_genting_2_strokedasharray: 114.615;
$gotong_jaya_strokedasharray: 119.408;
$awana_skyway_1_strokedasharray: 135.182;
$awana_skyway_2_strokedasharray: 114.615;
$kl_tower_1_strokedasharray: 252.711;
$kl_tower_2_strokedasharray: 132.93;
$pavilion_kl_1_strokedasharray: 289.002;
$pavilion_kl_2_strokedasharray: 132.93;
$klia_1_strokedasharray: 214.335;
$klia_2_strokedasharray: 132.93;

#resort_world_genting_1 {
  stroke-dasharray: $resort_world_genting_1_strokedasharray;
  stroke-dashoffset: $resort_world_genting_1_strokedasharray;
  // transition: all 3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

#resort_world_genting_2 {
  stroke-dasharray: $resort_world_genting_2_strokedasharray;
  stroke-dashoffset: $resort_world_genting_2_strokedasharray;
  // transition: all 3s cubic-bezier(0.075, 0.82, 0.165, 1) .7s;
}

#gotong_jaya {
  stroke-dasharray: $gotong_jaya_strokedasharray;
  stroke-dashoffset: $gotong_jaya_strokedasharray;
  // transition: all 3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

#awana_skyway_1 {
  stroke-dasharray: $awana_skyway_1_strokedasharray;
  stroke-dashoffset: $awana_skyway_1_strokedasharray;
  // transition: all 3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

#awana_skyway_2 {
  stroke-dasharray: $awana_skyway_2_strokedasharray;
  stroke-dashoffset: $awana_skyway_2_strokedasharray;
  // transition: all 3s cubic-bezier(0.075, 0.82, 0.165, 1) .7s;
}

#kl_tower_1 {
  stroke-dasharray: $kl_tower_1_strokedasharray;
  stroke-dashoffset: $kl_tower_1_strokedasharray;
  // transition: all 3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

#kl_tower_2 {
  stroke-dasharray: $kl_tower_2_strokedasharray;
  stroke-dashoffset: $kl_tower_2_strokedasharray;
  // transition: all 3s cubic-bezier(0.075, 0.82, 0.165, 1) .7s;
}

#pavilion_kl_1 {
  stroke-dasharray: $pavilion_kl_1_strokedasharray;
  stroke-dashoffset: $pavilion_kl_1_strokedasharray;
  // transition: all 3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

#pavilion_kl_2 {
  stroke-dasharray: $pavilion_kl_2_strokedasharray;
  stroke-dashoffset: $pavilion_kl_2_strokedasharray;
  // transition: all 3s cubic-bezier(0.075, 0.82, 0.165, 1) .7s;
}

#klia_1 {
  stroke-dasharray: $klia_1_strokedasharray;
  stroke-dashoffset: $klia_1_strokedasharray;
  // transition: all 3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

#klia_2 {
  stroke-dasharray: $klia_2_strokedasharray;
  stroke-dashoffset: $klia_2_strokedasharray;
  // transition: all 3s cubic-bezier(0.075, 0.82, 0.165, 1) .7s;
}

#resort_world_genting_1.active {
  stroke-dashoffset: #{$resort_world_genting_1_strokedasharray * 2};
}

#resort_world_genting_2.active {
  stroke-dashoffset: #{$resort_world_genting_2_strokedasharray * 2};
}

#gotong_jaya.active {
  stroke-dashoffset: #{$gotong_jaya_strokedasharray * 2};
}

#awana_skyway_1.active {
  stroke-dashoffset: #{$awana_skyway_1_strokedasharray * 2};
}

#awana_skyway_2.active {
  stroke-dashoffset: #{$awana_skyway_2_strokedasharray * 2};
}

#kl_tower_1.active {
  stroke-dashoffset: #{$kl_tower_1_strokedasharray * 2};
}

#kl_tower_2.active {
  stroke-dashoffset: #{$kl_tower_2_strokedasharray * 2};
}

#pavilion_kl_1.active {
  stroke-dashoffset: #{$pavilion_kl_1_strokedasharray * 2};
}

#pavilion_kl_2.active {
  stroke-dashoffset: #{$pavilion_kl_2_strokedasharray * 2};
}

#klia_1.active {
  stroke-dashoffset: #{$klia_1_strokedasharray * 2};
}

#klia_2.active {
  stroke-dashoffset: #{$klia_2_strokedasharray * 2};
}

.location-map-container img {
  position: absolute;
  top: 47%;
  width: 30%;
  right: 19%;
}

.floating {
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-5px);
  }

  100% {
    transform: translatey(0px);
  }
}

label {
  color: $color-label;
  font-family: "Petita";
  font-weight: 700;
  font-size: 1rem;
}

.home-registration .form-check-label {
  font-size: 0.9rem;
  // font-family: "Petita";
  // font-weight: 400;
}

.accordian-button {
  font-family: "Petita";
  font-weight: 700;
  color: $color-label;
}

.form-control {
  border: unset;
  color: #6B6B6B;
  font-family: "Petita";
  font-weight: 400;
  transition: all .2s ease-in-out;
}

.form-control:focus {
  outline: none !important;
  box-shadow: unset !important;
}

.home-registration {
  background: $color-text-brown;
}

.form-control {
  height: calc(1rem + 1rem + 1rem);
}

.select-control:after {
  border: unset;
  border-width: unset;
  background: $color-label;
  clip-path: polygon(0 0, 100% 0, 0% 100%);
  transform: translateY(-50%) rotate(-135deg);
  height: 0.8rem;
  width: 0.8rem;
}

.form-check-label {
  color: inherit;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Petita";
  font-weight: 700;
}

input~.checkmark {
  background-color: #ffffff;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0;
}

.form-check-inline {
  margin-right: 0;
}

.form-check-label {
  color: inherit;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // font-family: $font_para;
  font-size: 80% !important;
}

.form-check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
}


/* On mouse-over, add a grey background color */

input~.checkmark {
  background-color: #fff;
}


/* When the checkbox is checked, add a blue background */

input:checked~.checkmark {
  // background-color: #2196F3;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}


/* Show the checkmark when checked */

input:checked~.checkmark:after {
  // display: block;
  transform: rotate(45deg) scale(1);
  opacity: 1;
  visibility: visible;
}


/* Style the checkmark/indicator */

.checkmark:after {
  left: 8px;
  top: 2px;
  width: 6px;
  height: 13px;
  border: solid $color-text-brown;
  border-width: 0 3px 3px 0;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg) scale(1.5);
  opacity: 0;
  visibility: hidden;
  display: block;
}

input[name="sources_others"] {
  background: transparent;
  border: unset;
  border-bottom: 1px solid #000;
  margin-bottom: 12px;
}

input[name="sources_others"]:focus {
  outline: none !important;
  box-shadow: unset !important;
}

.submit-btn {
  padding: 1rem 2rem;
  border: solid 1px #fff;
  border-radius: .5rem;
  text-transform: uppercase;
  background: transparent;
  color: #fff;
}

.submit-btn:focus {
  box-shadow: unset;
  outline: none;
}

.submit-btn:hover {
  padding: 1rem 2rem;
  border: solid 1px $color-theme;
  border-radius: .5rem;
  text-transform: uppercase;
  color: $color-theme;
}

.home-registration-tnc .form-check-label {
  text-transform: unset !important;
  font-size: 1rem !important;
}

.home-registration-tnc .form-check-label a {
  color: $color-theme;
}

@keyframes fade-left {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }

  100% {
    transform: translateX(-10px);
    opacity: 0;
  }
}

.social-container {
  position: fixed;
  left: 1.5rem;
  bottom: 3rem;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  transition: all .5s ease-in-out;

  @include media-breakpoint-down(lg) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem 0;
    // background: #fff;
  }

  @include media-breakpoint-down(sm) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 6), auto));
    gap: 1rem 0;
    width: 100%;
  }
}

.social-container>div {
  // background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: .5rem 0;
  // border-radius: 50%;
  transform: translate(-50px, 0px);
  opacity: 0;
  position: relative;

  @include media-breakpoint-down(lg) {
    margin: 0 .5rem .5rem .5rem;
    transform: translate(0px, 50px);
  }

  @include media-breakpoint-down(sm) {
    margin: 0;
  }
}

.social-container>div:before {
  content: attr(data-social-title);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 2rem;
  z-index: -1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 100%;
  width: auto;
  white-space: nowrap;
  text-transform: uppercase;
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0;
  font-size: 80%;
  visibility: hidden;
}

.social-container>div:hover:before {

  @include media-breakpoint-up(xl) {
    padding: 0 2rem 0 calc(100% + .5rem);
    opacity: 1;
    visibility: visible;
  }
}

.social-container div a {
  padding: 1rem;
  background: #fff;
  border-radius: 2rem;

  @include media-breakpoint-down(sm) {
    // padding: .5rem;
  }
}

.social-container div a img {
  max-width: 25px;

  @include media-breakpoint-down(lg) {
    max-width: 20px;
  }
}

.back-to-top {
  position: fixed;
  bottom: 6rem;
  right: 1rem;
  z-index: 1000;
  color: #fff;
  font-size: 80%;
  text-align: center;
  cursor: pointer;
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);

  @include media-breakpoint-down(sm) {
    font-size: 50%;
  }
}

.back-to-top svg {
  display: block;
  margin: 0 auto;
  margin-bottom: .5rem;
}

.back-to-top:hover {
  color: $color-theme;
}

.back-to-top:hover svg path {
  fill: $color-theme;
}

.back-to-top.hidden {
  transform: scale(0);
  opacity: 0;
}

.form-row.acquisition {
  margin: 0;
}

.form-row.acquisition .form-check {
  flex: 0 0 33.33333%;
  align-items: center;
  padding-left: 0;
  margin-bottom: 1rem;
  display: flex;

  @include media-breakpoint-down(md) {
    flex: 0 0 50%;
  }

  @include media-breakpoint-down(sm) {
    flex: 0 0 100%;
  }
}

.form-row.acquisition .form-check .form-check-label {
  margin-bottom: 0;
}

.form-row.acquisition .form-check:last-child {
  display: flex;
  flex-direction: row;
  flex: 1 0 33.33333%;
}

.form-row.acquisition .form-check:last-child .form-check-label {
  margin-right: 1rem;
}

.form-row.acquisition .form-check:last-child input {
  // flex: 0 0 auto;
  max-width: 100%;
  height: auto;
}

.form-control:disabled,
.form-control[readonly] {
  opacity: .7;
}

.form-group.renno {
  // visibility: hidden;
  // opacity: 0;
  display: none;
  // transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

// .form-group.renno.active {
//   visibility: visible;
//   opacity: 1;
//   display: block;
// }