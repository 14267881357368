/**
 * @component Footer
 */

.footer {
  background-color: $color-text-brown;
  text-align: center;
  font-size: 80%;
  display: flex;
  align-items: center;
  padding: $spacing-6 0;
  color: #fff;

  @include on-tablet {
    height: 70px;
    font-size: 14px;
    padding: 0;
  }
}

.footer a {
  // color: $color-text;
  color: #fff;
  transition: color .5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.footer a:hover {
  // color: $color-text;
  color: $color-theme;
}
