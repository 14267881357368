nav {
    position: fixed;
    top: 0;
    left: 0;
    background: $color-nav-bg;
    opacity: 0;
    transform: translateY(-100%);
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

nav.animated {
    transform: unset !important;
}
nav .logo {
    max-width: 175px;
    transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: scale(0);
    animation: fadedown 1s cubic-bezier(0.075, 0.82, 0.165, 1) 1s forwards;

    @include media-breakpoint-down(md) {
        max-width: 150px;
    }
}
nav .logo,
nav .menu-hamburger {
    position: relative;
    z-index: 1001;
}

.navi-menu ul {
    list-style: none;
    padding-left: 0;
    transition: all .3s ease-in-out;

    @include media-breakpoint-down (md) {
        margin: 30px 0;
    }
}
.navi-menu ul li {
    opacity: 0;
}
.navi-menu ul li a {
    text-transform: uppercase;
    font-weight: 300;
    font-size: .9rem;
    letter-spacing: .1rem;
    transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.navi-menu ul li a.register-btn {
    padding: 1rem 2rem;
    border: solid 1px #fff;
    border-radius: .5rem;
}

.navi-menu ul li a.register-btn:hover {
    border-color: $color-theme;
}

.navi-contain {
    transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);

    @include media-breakpoint-down(md) {
        position: fixed;
        height: 100vh;
        width: 100%;
        background: $color-mobile-nav-bg;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        transform: translateX(100%);
    }
}

.navi-contain.is-active {
    @include media-breakpoint-down(md) {
        transform: translateX(0);
    }
}

.navbar-nav li {
    padding: 1rem;
}

#nav-icon {
    width: 40px;
    height: 25px;
    position: relative;
    /* margin: 50px auto; */
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#nav-icon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $color-theme;
    background-size: cover;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

#nav-icon span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    background-position: top;
}

#nav-icon span:nth-child(2) {
    top: 10px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    background-position: center;
}

#nav-icon span:nth-child(3) {
    top: 20px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    background-position: bottom;
}

#nav-icon.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -3px;
    left: 8px;
}

#nav-icon.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

#nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 26px;
    left: 8px;
}

@for $i from 1 through 5 {
    // ul:nth-child(3n + #{$i}) {
    //   background-color: lighten($base-color, $i * 5%);
    // }

    .navi-menu ul li:nth-child(#{$i}) {
        animation: fadeleft 1s cubic-bezier(0.075, 0.82, 0.165, 1) #{($i * 0.1) + 1}s forwards;
    }
  }

  @keyframes fadeleft {
      0% {
          opacity: 0;
          transform: translateX(20px);
      }
      100% {
          opacity: 1;
          transform: translateX(0);
      }
  }

  @keyframes fadedown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}