/**
 * @fonts Avenir
 */

 //roman (regular)
 @font-face {
    font-family: 'Avenir LS Std';
    font-display: swap;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    src: url('../fonts/avenir/Avenir_roman.ttf') format('truetype')
  }
  
  //medium
  @font-face {
    font-family: 'Avenir LS Std';
    font-display: swap;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    src: url('../fonts/avenir/Avenir_medium.ttf') format('truetype');
  }

  //heavy
  @font-face {
    font-family: 'Avenir LS Std';
    font-display: swap;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    src: url('../fonts/avenir/Avenir_heavy.ttf') format('truetype');
  }

  //black (more bold)
  @font-face {
    font-family: 'Avenir LS Std';
    font-display: swap;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    src: url('../fonts/avenir/Avenir_black.ttf') format('truetype');
  }


  //svg font-family
   //roman (regular)
 @font-face {
  font-family: 'Avenir-Roman';
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url('../fonts/avenir/Avenir_roman.ttf') format('truetype')
}