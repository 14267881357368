/**
 * @base Typography definitions
 */

$typeface-primary: 'Avenir LS Std';
$typeface-secondary: 'Bauer Bodoni',
'Didot',
sans-serif;

html,
body {
  font-size: 16px;
  font-family: $typeface-primary;
  line-height: 1.4;
  font-weight: 500;

  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

a {
  color: #fff;
}

a:hover {
  color: $color-theme;
  text-decoration: none;
}

::selection {
  // background-color: rgba($color-secondary, 0.3);
  // background-color: background-color: $color-text-brown;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  font-family: $typeface-secondary;
  color: $color-theme;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
}

h2, .h2 {
  font-size: 2.5rem !important;
  line-height: 2.5rem !important;
}

h3,
.h3 {
  font-size: 2rem;
  line-height: 2rem;

  @include media-breakpoint-down(sm) {}
}

h4,
.h4 {
  font-size: 1.5rem;
  line-height: 1.5rem;

  @include media-breakpoint-down(sm) {}
}

.sub {
  font-size: 1.8rem;
  letter-spacing: .1rem;
  font-weight: 300;
}