/**
 * @base Color definitions
 */

/* Primary Color
/* -------------------------------------------------------------------------- */
$color-primary: rgb(81, 63, 47);
$color-primary-l1: lighten($color-primary, 10%);
$color-primary-l2: lighten($color-primary, 20%);
$color-primary-l3: lighten($color-primary, 30%);
$color-primary-l4: lighten($color-primary, 40%);
$color-primary-l5: lighten($color-primary, 50%);
$color-primary-d1: darken($color-primary, 5%);
$color-primary-d2: darken($color-primary, 10%);
$color-primary-d3: darken($color-primary, 15%);
$color-primary-d4: darken($color-primary, 20%);
$color-primary-d5: darken($color-primary, 25%);

/* Secondary Color
/* -------------------------------------------------------------------------- */
$color-secondary: rgb(213, 198, 155);
$color-secondary-l1: lighten($color-secondary, 10%);
$color-secondary-l2: lighten($color-secondary, 20%);
$color-secondary-l3: lighten($color-secondary, 30%);
$color-secondary-l4: lighten($color-secondary, 40%);
$color-secondary-l5: lighten($color-secondary, 50%);
$color-secondary-d1: darken($color-secondary, 5%);
$color-secondary-d2: darken($color-secondary, 10%);
$color-secondary-d3: darken($color-secondary, 15%);
$color-secondary-d4: darken($color-secondary, 20%);
$color-secondary-d5: darken($color-secondary, 25%);

/* Text Color
/* -------------------------------------------------------------------------- */
$color-text: rgb(40, 40, 40);
$color-text-d1: darken($color-text, 5%);
$color-text-d2: darken($color-text, 10%);
$color-text-d3: darken($color-text, 15%);
$color-text-d4: darken($color-text, 20%);
$color-text-d5: darken($color-text, 25%);

/*
/* -------------------------------------------------------------------------- */

$color-nav-bg : rgba(75, 77, 72, .7);
$color-mobile-nav-bg : rgb(75, 77, 72);
$color-theme : #BA8648;
$color-theme-2: #E5DDCC;
$color-text-brown: #6D5C4A;
$color-text-2: #525252;
$color-label: #CDA880;
$color-brown-bg: #645442;

$color-gallery-desc-span: rgba(109, 92, 74, 0.8);